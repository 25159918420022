import React from 'react';
import theme from '../../components/theme';
import { ThemeProvider } from 'emotion-theming';
import EligibilityForm from '../../components/eligibility/eligibilityForm';
import Wrapper from '../../components/wrapper';
import { SimpleNavbar } from '../../components/overlay';
import { Box } from 'rebass';
import { DocumentHeader } from '../../components/layout';

function EligibilityFormPage({ location }) {
  return (
    <ThemeProvider theme={theme}>
      <DocumentHeader title={'Eligibility'} />
      <Wrapper>
        <SimpleNavbar location={location} />
        <Box pt={'7vh'}>
          <EligibilityForm />
        </Box>
      </Wrapper>
    </ThemeProvider>
  );
}

export default EligibilityFormPage;
